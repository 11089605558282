import {
  Box,
  CssBaseline,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import Editor from "./Editor";

export default function EditorComponent({
  questionContent,
  setQuestionContent,
}) {
  const systemSettingsPrefersDarkMode = useMediaQuery(
    "(prefers-color-scheme: dark)"
  );
  const [paletteMode, setPaletteMode] = useState(
    systemSettingsPrefersDarkMode ? "dark" : "light"
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: paletteMode,
          secondary: {
            main: "#42B81A",
          },
        },
      }),
    [paletteMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Editor
          questionContent={questionContent}
          setQuestionContent={setQuestionContent}
        />
      </Box>
    </ThemeProvider>
  );
}
